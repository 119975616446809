import Vue from 'vue'

/** 全局注册,has,按钮，权限指令*/
Vue.directive('has',{
    bind(el,bindings) {
        //所有权限
        const permissions = sessionStorage.getItem('role')
        //所需权限
        const needPermission = bindings.value
        const hasPermission = permissions.includes(needPermission)


        //权限判断,display
        for (let i= 0 ; i <= permissions.length ; i++ ) {
            if (permissions.indexOf('AdminPermission') > -1) {
                el.style.display = 'inline'
            } else if(!hasPermission) {
                el.style.display = 'none'
            }

        }

        // console.log('el',el)
        // console.log('bindings',bindings.value)
    }
})
