import Vue from "vue";
import VueRouter from "vue-router";
// import Login from '@/views/platform/Login';


Vue.use(VueRouter);

//配置公共路由规则（懒加载）
export const constantRoutes = [

    { path: "/login", name: "login", component: () => import("@/login/Login") },
    { path: "/register", name: "register", component: () => import("@/login/Register") },
    { path: "/demo1", name: "demo1", component: () => import("@/views/power/Demo1") },
    { path: "/qrCode", name: "qrCode", component: () => import("@/qrCode/QrCode") },
    { path: "/mBlow", name: "mBlow", component: () => import("@/views/Mobile/mBlow/mBlow") },

    {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/Home"),
        redirect: "/welcome",
        children: [
            { path: "/welcome", name: "welcome", component: () => import("@/views/home/Welcome") },


            { path: "/user", name: "user", component: () => import("@/views/user/User") },
            { path: "/adminUser", name: "adminUser", component: () => import("@/views/user/AdminUser") },


            { path: "/platform", name: "platform", component: () => import("@/views/platform/Platform") },
            { path: "/operator", name: "operator", component: () => import("@/views/platform/Operator") },
            { path: "/project", name: "project", component: () => import("@/views/platform/Project") },


            { path: "/rechargeDetail", name: "rechargeDetail", component: () => import("@/components/orderDetails/RechargeDetail") },
            { path: "/payDetail", name: "payDetail", component: () => import("@/components/orderDetails/PayDetail") },


            { path: "/activity", name: "activity", component: () => import("@/views/activity/Activity") },
            { path: "/activeDetail", name: "activeDetail", component: () => import("@/views/activity/ActiveDetail") },


            { path: "/blowing", name: "blowing", component: () => import("@/views/main/blow/Blowing") },
            { path: "/blowDetail", name: "blowDetail", component: () => import("@/views/main/blow/BlowDetail") },
            { path: "/blowServe", name: "blowServe", component: () => import("@/views/main/blow/BlowServe") },


            { path: "/washing", name: "washing", component: () => import("@/views/main/wash/Washing") },
            { path: "/washDetail", name: "washDetail", component: () => import("@/views/main/wash/WashDetail") },
            { path: "/washServe", name: "washServe", component: () => import("@/views/main/wash/WashServe") },


            { path: "/piles", name: "piles", component: () => import("@/views/main/piles/Piles") },
            { path: "/pilesDetail",name: "pilesDetail", component: () => import("@/views/main/piles/pilesDetail") },
            { path: "/pilesServe",name: "pilesServe", component: () =>import("@/views/main/piles/pilesServe") },


            { path: "/agent", name: "agent", component: () => import("@/views/platform/Agent") },


            { path: "/withdraw", name: "withdraw", component: () => import("@/views/finance/Withdraw") },


            { path: "/rights", name: "rights", component: () => import("@/views/power/Rights") },
            { path: "/roles", name: "roles", component: () => import("@/views/power/Roles") },


            { path: "/paymentOrder", name: "paymentOrder", component: () => import("@/views/orders/PaymentOrder") },
            { path: "/rechargeOrder", name: "rechargeOrder", component: () => import("@/views/orders/RechargeOrder") },


            { path: "/paymentRefund", name: "paymentRefund", component: () => import("@/views/orders/PaymentRefund") },
            { path: "/rechargeRefund", name: "rechargeRefund", component: () => import("@/views/orders/RechargeRefund") },


        ]},
    // 路由重定向：下面的代码意思是：当访问一个空字符串的路径时，给重定向到home路由上

    { path: "*", name: "404", component: () => import("@/views/404") },

]

//配置权限路由规则（懒加载）
export const asyncRoutes = [

]



//路由实例对象
const router = new VueRouter({
    routes: constantRoutes,asyncRoutes,
    // 移除地址栏里边的#号键
    mode: "history",
    base: process.env.BASE_URL,

});

//全局配置导航守卫
router.beforeEach((to, from, next) => {
    //直接通行的页面路由
    if (to.path === '/login') return next();


    //获取缓存的 token
    const tokenStr = window.sessionStorage.getItem('token')
    //没有token 强制跳转 login
    if (!tokenStr) return next('/login')
    next();
})


export default router
