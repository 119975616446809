<template>
  <el-card>
<!--  通过id索引  -->
    <div id="myChart2" :style="{width: '100%', height: '500px'}"></div>
  </el-card>

</template>

<script>


export default {
  name: "Echart2",
  props: ["Echart2"],
  data () {
    return {
     pie:[
       {
         value: 5555,
         name: '直接访问'
       },
       {
         value: 3333,
         name: '广告运营'
       },
       {
         value: 1548,
         name: '搜索引擎'
       }
     ],
      msg: 'Welcome to Your Vue.js App'
    }
  },
  mounted(){
    this.drawLine();
  },
  methods: {
    drawLine(){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart2'))
      // 绘制图表
      myChart.setOption({

        title: { text: '饼状数据图' },


        series: [
          {
            type: 'pie',
            data: this.pie
          }
        ]
      });
    }
  },
}
</script>

<style lang="less" scoped>
.el-card {
  margin: 10px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.15) !important;
}
</style>
