import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);



// import moment from 'moment'
//
// Vue.filter('moment', function (value, formatString) {
//   formatString = formatString || 'YYYY-MM-DD HH:mm:ss';
//   return moment(value).format(formatString);
// });

//导入全局样式
import './assets/css/global.css'
// 引入echarts
import echarts from 'echarts'
//导入全局注册组件  Echart
import Echart1 from './components/report/Echart1.vue'
import Echart2 from './components/report/Echart2.vue'

//全局has,按钮权限指令
import './directives/has'

const loading = {

  install: function (Vue) {

    Vue.component('Echart1',Echart1)
    Vue.component('Echart2',Echart2)


  }

}

Vue.use(loading);

export default loading;

//全局配置挂载echarts
Vue.prototype.$echarts = echarts

Vue.use(ElementUI);


//配置请求根路径



axios.defaults.baseURL = 'https://iotapi.cnbolanqi.com/v1';



// axios.defaults.baseURL = 'http://192.168.0.3:8000/v1';


//axios请求拦截
axios.interceptors.request.use(config => {
  // console.log(config)
  //为请求头对象，添加Token 验证的Authorization
  config.headers.Authorization = sessionStorage.getItem("token")
  return config
})

//全局配置挂载axios(缺点：不利于API复用）
Vue.prototype.$http = axios

//阻止启动生产消息，常用作指令
Vue.config.productionTip = false

//
new Vue({
  router,
  render: h => h(App),


}).$mount('#app')




