<template>
  <el-card>
    <div id="myChart" :style="{width: '100%', height: '500px'}"></div>
  </el-card>

</template>

<script>


export default {
  name: "Echart1",
  props: ["Echart1"],
  data () {
    return {
      xData:["洗衣机","吹风机","直饮水","人工智能","平台","项目","用户","客户","代理商"],
      yData:[777, 333 , 999 , 444 , 888 , 555 , 444 , 222 , 555],
      zData:[555, 888 , 555 , 666 , 444 , 777 , 777 , 444 , 888],
      msg: 'Welcome to Your Vue.js App'
    }
  },
  mounted(){
    this.drawLine();
  },
  methods: {
    drawLine(){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({

        title: { text: '柱状数据图' },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.xData
        },
        yAxis: {
          type: 'value'
        },
        series: [
            {
          name: '上月数量',
          type: 'bar',

          // 此系列自己的调色盘。
          color: [

            '#fb7293',
            '#E062AE',
            '#E690D1',
            '#e7bcf3',
            '#9d96f5',
            '#8378EA',
            '#96BFFF'
          ],
          data: this.yData
        },
          {
            name: '当月数量',
            type: 'bar',

            // 此系列自己的调色盘。
            color: [

              '#32C5E9',
              '#67E0E3',
              '#9FE6B8',
              '#FFDB5C',
              '#ff9f7f',
              '#fb7293',
              '#E062AE',
              '#E690D1',
              '#e7bcf3',
              '#9d96f5',
              '#8378EA',
              '#96BFFF'
            ],
            data: this.zData
          },
        ]
      });
    }
  },
}
</script>

<style lang="less" scoped>
.el-card {
  margin: 10px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.15) !important;
}
</style>
